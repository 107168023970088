import React from 'react';

import SEO from 'components/SEO';

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <h1>Seite nicht gefunden</h1>
    <p>Sie sind auf einer Seite gelandet, die es gar nicht gibt. Ja sowas!</p>
  </>
);

export default NotFoundPage;
